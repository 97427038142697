'use strict';
jQuery(document).ready(function($){

  var controller = new ScrollMagic.Controller();
	
   
        //TIMELINE 0
        var timelinename0 = anime.timeline({
            autoplay:false
        });
    
    
        timelinename0.add({
            targets: ['.home .number-0 .img', '.home .number-0 .txt'],
            easing: 'easeInOutSine',
            duration: 400,
            opacity:1,
            delay: 0,
            loop: false
        });
        var scene0 = new ScrollMagic.Scene({
        triggerElement: '.home .number-0',
            duration: 400,
            offset:-350
        })
        .on("progress", function (event) {
            timelinename0.seek(timelinename0.duration * event.progress);
        })
        .addTo(controller);
        
         //TIMELINE 1
        var timelinename1 = anime.timeline({
            autoplay:false
        });
       if(screen.width > 768){
            timelinename1.add({
                targets: ['.home .number-1 polyline'],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 600,
                direction:'normal',
                delay: function(el, i) {
                    return i * 250;
                },
                loop: false
            });
            timelinename1.add({
                targets: ['.home .number-1 .img', '.home .number-1 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            }, '-=400');
            var scene1 = new ScrollMagic.Scene({
                triggerElement: '.home .number-1',
                    duration: 1000,
                    offset:-350
                })
                .on("progress", function (event) {
                    timelinename1.seek(timelinename1.duration * event.progress);
                })
                .addTo(controller);
       } else {
            timelinename1.add({
                targets: ['.home .number-1 .img', '.home .number-1 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            });
            var scene1 = new ScrollMagic.Scene({
                triggerElement: '.home .number-1',
                    duration: 400,
                    offset:-150
                })
                .on("progress", function (event) {
                    timelinename1.seek(timelinename1.duration * event.progress);
                })
                .addTo(controller);
       }
       
      
       

         //TIMELINE 2
         var timelinename2 = anime.timeline({
            autoplay:false
        });
        if(screen.width > 768){
            timelinename2.add({
                targets: ['.home .number-2 polyline'],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 600,
                direction:'normal',
                delay: function(el, i) {
                    return i * 250;
                },
                loop: false
            });
            timelinename2.add({
                targets: ['.home .number-2 .img', '.home .number-2 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            }, '-=400');
            var scene2 = new ScrollMagic.Scene({
                triggerElement: '.home .number-2',
                    duration: 1000,
                    offset:-350
                })
                .on("progress", function (event) {
                    timelinename2.seek(timelinename2.duration * event.progress);
                })
                .addTo(controller);
        } else {
            timelinename2.add({
                targets: ['.home .number-2 .img', '.home .number-2 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            });
            var scene2 = new ScrollMagic.Scene({
                triggerElement: '.home .number-2',
                    duration: 400,
                    offset:-150
                })
                .on("progress", function (event) {
                    timelinename2.seek(timelinename2.duration * event.progress);
                })
                .addTo(controller);
        }
        
        
        
        //TIMELINE 3
        var timelinename3 = anime.timeline({
            autoplay:false
        });
        if(screen.width > 768){
            timelinename3.add({
                targets: ['.home .number-3 polyline'],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 600,
                direction:'normal',
                delay: function(el, i) {
                    return i * 250;
                },
                loop: false
            });
            timelinename3.add({
                targets: ['.home .number-3 .img', '.home .number-3 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            }, '-=400');
            var scene3 = new ScrollMagic.Scene({
                triggerElement: '.home .number-3',
                    duration: 1000,
                    offset:-350
                })
                .on("progress", function (event) {
                    timelinename3.seek(timelinename3.duration * event.progress);
                })
                .addTo(controller);
        } else {
            timelinename3.add({
                targets: ['.home .number-3 .img', '.home .number-3 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            });
            var scene3 = new ScrollMagic.Scene({
                triggerElement: '.home .number-3',
                    duration: 400,
                    offset:-150
                })
                .on("progress", function (event) {
                    timelinename3.seek(timelinename3.duration * event.progress);
                })
                .addTo(controller);
        }
        
       
        
        //TIMELINE 4
        var timelinename4 = anime.timeline({
            autoplay:false
        });
        if(screen.width > 768){
            timelinename4.add({
                targets: ['.home .number-4 polyline'],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 600,
                direction:'normal',
                delay: function(el, i) {
                    return i * 250;
                },
                loop: false
            });
            timelinename4.add({
                targets: ['.home .number-4 .img', '.home .number-4 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            }, '-=400');
            var scene4 = new ScrollMagic.Scene({
                triggerElement: '.home .number-4',
                    duration: 1000,
                    offset:-350
                })
                .on("progress", function (event) {
                    timelinename4.seek(timelinename4.duration * event.progress);
                })
                .addTo(controller);
        } else {
            timelinename4.add({
                targets: ['.home .number-4 .img', '.home .number-4 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            });
            var scene4 = new ScrollMagic.Scene({
                triggerElement: '.home .number-4',
                    duration: 400,
                    offset:-150
                })
                .on("progress", function (event) {
                    timelinename4.seek(timelinename4.duration * event.progress);
                })
                .addTo(controller);
        }
       
       
        
        //TIMELINE 5
        var timelinename5 = anime.timeline({
            autoplay:false
        });
        if(screen.width > 768){
            timelinename5.add({
                targets: ['.home .number-5 polyline'],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 600,
                direction:'normal',
                delay: function(el, i) {
                    return i * 250;
                },
                loop: false
            });
            timelinename5.add({
                targets: ['.home .number-5 .img', '.home .number-5 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            }, '-=400');
            var scene5 = new ScrollMagic.Scene({
                triggerElement: '.home .number-5',
                    duration: 1000,
                    offset:-350
                })
                .on("progress", function (event) {
                    timelinename5.seek(timelinename5.duration * event.progress);
                })
                .addTo(controller);
        } else {
            timelinename5.add({
                targets: ['.home .number-5 .img', '.home .number-5 .txt'],
                easing: 'easeInOutSine',
                duration: 400,
                opacity:1,
                delay: 0,
                loop: false
            });
            var scene5 = new ScrollMagic.Scene({
                triggerElement: '.home .number-5',
                    duration: 400,
                    offset:-150
                })
                .on("progress", function (event) {
                    timelinename5.seek(timelinename5.duration * event.progress);
                })
                .addTo(controller);
        }

});